import React from 'react';
import AnimatedIconRow from './AnimatedIconRow';
import AnimatedIconRowAlt from './AnimatedIconRowAlt';
import { Heading, SubHeading, IconRow, Section, TextWrapper } from './HeroElements';


const HeroSection = () => {
    return (
        <>
            <Section>
                <TextWrapper>
                    <Heading>
                        We deliver the services you need
                    </Heading>
                    <SubHeading>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </SubHeading>
                </TextWrapper>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
                <IconRow>
                    <AnimatedIconRow></AnimatedIconRow>
                </IconRow>
                <IconRow>
                    <AnimatedIconRowAlt></AnimatedIconRowAlt>
                </IconRow>
            </Section>
        </>
    )
}

export default HeroSection