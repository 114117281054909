import React from 'react';
import { ParallaxContainer, ParallaxImageBg, ParallaxImageWrapper, TextSection, TextWrapper } from './ParallaxInfoElements';
import Image from '../../images/background1.jpg';


const ParallaxInfoSection = () => {
    return (
        <>
            <ParallaxContainer>
                <ParallaxImageWrapper>
                    <ParallaxImageBg src={Image} />
                </ParallaxImageWrapper>
                <TextSection>
                    <TextWrapper>
                        <br /><br />
                        
                        <br /><br />
                    </TextWrapper>
                </TextSection>
                
            </ParallaxContainer>
        </>
    )
}

export default ParallaxInfoSection