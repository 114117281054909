import React from 'react';
import { InnerDivOdd, MaterialIcon } from './HeroElements';


const AnimatedIconRow = () => {
    return (
        <InnerDivOdd>
            <MaterialIcon>flash_on</MaterialIcon>
            <MaterialIcon>blur_on</MaterialIcon>
            <MaterialIcon>bubble_chart</MaterialIcon>
            <MaterialIcon>bug_report</MaterialIcon>
            <MaterialIcon>build</MaterialIcon>
            <MaterialIcon>business</MaterialIcon>
            <MaterialIcon>business_center</MaterialIcon>
            <MaterialIcon>cake</MaterialIcon>
            <MaterialIcon>camera_alt</MaterialIcon>
            <MaterialIcon>chat</MaterialIcon>
            <MaterialIcon>cast_connected</MaterialIcon>
            <MaterialIcon>cloud</MaterialIcon>
            <MaterialIcon>color_lens</MaterialIcon>
            <MaterialIcon>computer</MaterialIcon>
            <MaterialIcon>dashboard</MaterialIcon>
            <MaterialIcon>drafts</MaterialIcon>
            <MaterialIcon>equalizer</MaterialIcon>
            <MaterialIcon>favorite</MaterialIcon>
            <MaterialIcon>fingerprint</MaterialIcon>
            <MaterialIcon>grade</MaterialIcon>
            <MaterialIcon>home</MaterialIcon>
            <MaterialIcon>hourglass_full</MaterialIcon>
            <MaterialIcon>keyboard_voice</MaterialIcon>
            <MaterialIcon>language</MaterialIcon>
            <MaterialIcon>laptop_mac</MaterialIcon>
            <MaterialIcon>link</MaterialIcon>
            <MaterialIcon>live_tv</MaterialIcon>
            <MaterialIcon>local_offer</MaterialIcon>
            <MaterialIcon>local_pizza</MaterialIcon>
            <MaterialIcon>memory</MaterialIcon>
            <MaterialIcon>mood</MaterialIcon>
            <MaterialIcon>mouse</MaterialIcon>
            <MaterialIcon>near_me</MaterialIcon>
            <MaterialIcon>rss_feed</MaterialIcon>
            <MaterialIcon>notifications_active</MaterialIcon>
            <MaterialIcon>palette</MaterialIcon>
            <MaterialIcon>people</MaterialIcon>
            <MaterialIcon>pie_chart</MaterialIcon>
            <MaterialIcon>public</MaterialIcon>
            <MaterialIcon>thumb_up</MaterialIcon>
        </InnerDivOdd>
    )
}

export default AnimatedIconRow