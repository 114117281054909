import styled, { keyframes } from 'styled-components';
import { ColorGreen, ColorWhite } from '../../Theme';

export const Section = styled.div`
    padding: 0;
    position: relative;
    width: 100%;
    height: 80vh;
    background-color: #101935;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const IconRow = styled.div`
    position: relative;
    top: -50%;
    left: -10%;
    width: 100%;
    display: flex;
    padding: 10px 0;
    white-space: nowrap;
    cursor: default;
    transform: rotate(-30deg);
`

export const MaterialIcon = styled.i`
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;

    color: rgba(0, 0, 0, 0.5);
    transition: 1s;
    padding: 0 5px;
    user-select: none;
    cursor: default;
    font-size: 64px;

    :hover {
        transition: 0s;
        color: ${ColorGreen};
        text-shadow: 0 0 120px ${ColorGreen};
    }
`

export const TextWrapper = styled.div`
  z-index: 5;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
`

export const Heading = styled.h1`
  font-family: 'Montserrat', sans-serif;
  color: ${ColorWhite};
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
`

export const SubHeading = styled.h3`
  color: ${ColorWhite};
  font-family: 'Montserrat', sans-serif;
`

// -------------------------------------------------||
// Animation keyframes -----------------------------||

const animate1 = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
`

const animate2 = keyframes`
  0% {
    transform: translateX(-20%);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-20%);
  }
`

// -------------------------------------------------||
// Components to be animated -----------------------||

export const InnerDivOdd = styled.div`
    animation: ${animate1} 20s linear infinite;
`

export const InnerDivEven = styled.div`
    animation: ${animate2} 20s linear infinite;
`

