import styled from 'styled-components';

export const ParallaxContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: 500px;
`

export const ParallaxImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
`

export const ParallaxImageBg = styled.img`
    opacity: 0;
    position: absolute;
    left: 50%;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`

export const TextSection = styled.div`
    padding-top: 1rem;
    padding-bottom: 0;
`

export const TextWrapper = styled.div`
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;

    @media only screen and (min-width: 601px) {
        width: 85%;
    }

    @media only screen and (min-width: 993px) {
        width: 70%;
    }
}
`