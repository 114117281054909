export const homeObjOne = {
    id: 'appdev',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Application Development',
    headline: 'Custom Software to meet your needs',
    description: 'Our digital solutions are tailored to meet your requirements and exceed your expectations at the same time. "How is that possible?"',
    buttonLabel: 'Find Out',
    imgStart: false,
    img: require('../../images/svg-6.svg').default,
    alt: 'Car',
    dark: false,
    primary: true,
    darkText: true
}

export const homeObjTwo = {
    id: 'cybersec',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Cyber Security',
    headline: 'Effective Cyber Security Solutions',
    description: 'Focus on running your business and outsource your security worries to us.',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/svg-7.svg').default,
    alt: 'Piggy bank',
    dark: false,
    primary: true,
    darkText: false
}
