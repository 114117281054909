import './App.css';
import { homeObjOne, homeObjTwo } from './components/FeaturesSection/Data';
import FeaturesSection from './components/FeaturesSection';
import HeroSection from './components/HeroSection';
import ParallaxInfoSection from './components/ParallaxInfoSection';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import { useState } from 'react';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <FeaturesSection {...homeObjOne} />
      <FeaturesSection {...homeObjTwo} />
      <ParallaxInfoSection id="parallax" />
      <Footer />
    </>
  );
}

export default App;
