import React from 'react';
import { motion } from "framer-motion";

import { Button } from '../ButtonElements';
import { 
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img
} from './FeaturesElements';

const FeaturesSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <motion.div
                                initial={{ opacity: 0, x: 100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: false }}
                                transition={{ delay: 0.6 }}
                            >
                                <TextWrapper>
                                    <TopLine>{topLine}</TopLine>
                                    <Heading lightText={lightText}>{headline}</Heading>
                                    <Subtitle darkText={darkText}>{description}</Subtitle>
                                    <BtnWrap>
                                        <Button to='home' 
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact={true}
                                            offset={-80}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}>
                                            {buttonLabel}
                                        </Button>
                                    </BtnWrap>
                                </TextWrapper>
                            </motion.div>
                        </Column1>
                        
                        
                        <Column2>
                            <motion.div
                                initial={{ opacity: 0, x: 100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: false }}
                                transition={{ delay: 0.6 }}
                            >
                                <ImgWrap>
                                    <Img src={img} alt={alt} />
                                </ImgWrap>
                            </motion.div>
                        </Column2>
                        
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default FeaturesSection