export const ColorBlue = '#101935';

export const ColorWhite = '#E8E9EB';

export const ColorGreen = '#1C7C54';

export const ColorOrange = '#F77F00';

export const ColorRed = '#D62828';

