import { createGlobalStyle } from "styled-components";

const MaterialIcons = createGlobalStyle`
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v126/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
  }
  
`;

  export default MaterialIcons;